<template>
  <div>
    <cover-item
      v-for="item in app.coverLetter.coverItems"
      :item="item"
      :key="item._key"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import CoverItem from "./CoverItem.vue";

defineOptions({ name: "CoverItems" });

const appStore = useAppStore();
const { app } = storeToRefs(appStore);
</script>
