<template>
  <q-list class="q-pb-lg" padding>
    <q-item-label class="text-dark text-weight-bold" header>{{
      t("cv.section.experience.label")
    }}</q-item-label>
    <template v-for="work in app.cv.experience" :key="work._key">
      <q-item class="q-pb-none" style="min-height: fit-content">
        <q-item-section>
          <q-item-label>{{ work.title }}</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label caption>{{ work.year }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="q-mb-none q-pt-none">
        <q-item-section>
          <q-item-label caption class="text-dark">{{
            work.description
          }}</q-item-label>
          <q-item-label caption class="text-dark text-small">{{
            work.location
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <q-item-label class="q-mt-lg text-dark text-weight-bold" header>{{
      t("cv.section.educator.label")
    }}</q-item-label>
    <template v-for="ed in app.cv.educator" :key="ed._key">
      <q-item>
        <q-item-section>
          <q-item-label>{{ ed.title }}</q-item-label>
          <q-item-label caption>{{ ed.location }}</q-item-label>
        </q-item-section>
        <q-item-section side top>
          <q-item-label caption>{{ ed.date }}</q-item-label>
        </q-item-section>
      </q-item>
      <template v-for="(course, index) in ed.course" :key="course._key">
        <q-item
          :class="{ 'q-mb-lg': index === ed.course.length - 1 }"
          dense
          style="padding-bottom: 0"
        >
          <q-item-section>
            <q-item-label caption class="text-dark text-small">{{
              course.title
            }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label caption>{{ course.year }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </template>
    <q-item-label class="q-mt-lg text-dark text-weight-bold" header>{{
      t("cv.section.education.label")
    }}</q-item-label>
    <q-item>
      <q-item-section>
        <q-item-label>{{ app.cv.education.title }}</q-item-label>
        <q-item-label caption class="text-dark">{{
          app.cv.education.description
        }}</q-item-label>
        <q-item-label caption class="text-dark text-small">{{
          app.cv.education.location
        }}</q-item-label>
      </q-item-section>
      <q-item-section side top>
        <q-item-label caption>{{ app.cv.education.date }}</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "CvSection" });

const { t } = i18n.global;
const appStore = useAppStore();
const { app } = storeToRefs(appStore);
</script>
