<template>
  <q-layout view="lHr LpR fff">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          icon="menu"
          aria-label="Menu"
          @click="toggleLeftDrawer"
        />

        <q-toolbar-title>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="q-my-sm"
            version="1.1"
            viewBox="0 0 436.9 90"
            style="height: 32px"
          >
            <g>
              <g>
                <path
                  fill="#ffffff"
                  d="M122.1,21.3c-1.5,0-2.6,1.1-2.6,2.6v51.2c-3.7.7-7.8,1.5-12,1.5-9,0-12-3.4-12-13.1V23.9c0-1.5-1.1-2.6-2.2-2.6h-13.4v42.2c0,19.8,10.5,26.1,27.3,26.1s26.9-3.7,28-4.1V21.3h-13.1ZM327.9,88.9c1.1,0,2.6-1.1,2.6-2.6v-51.5c3.7-.7,7.8-1.5,12-1.5,9,0,12,3.4,12,13.1v40c0,1.5,1.1,2.6,2.2,2.6h13.4v-42.6c0-19.8-10.5-26.1-27.3-26.1s-26.9,3.7-28,4.1v64.6h13.1ZM274.1,20.2c-17.5,0-28.4,9.3-28.4,29.9v10.1c0,22.4,12,29.9,24.6,29.9s13.1-4.5,14.9-7.1v4.1c0,1.1,1.1,2.2,2.2,2.2h13.4V23.9c.4,0-12.7-3.7-26.9-3.7h0ZM285.7,72.4c-3,2.6-5.2,4.1-11.6,4.1-9,.4-12.7-5.2-12.7-16.8v-9.3c0-13.1,4.9-16.8,12.7-16.8s10.1.4,11.6.7v38.1ZM409.7,20.2c-17.6,0-28.4,9.3-28.4,29.9v10.1c0,22.4,12,29.9,25,29.9s13.1-4.5,14.9-7.1v4.1c0,1.1,1.1,2.2,2.2,2.2h13.4V23.9s-13.1-3.7-27.3-3.7ZM421.2,72.4c-3,2.6-5.2,4.1-11.6,4.1s-12.7-5.2-12.7-16.8v-9.3c0-13.4,4.9-16.8,12.7-16.8s10.1.4,11.6.7v38.1ZM0,88.9h14.2c1.1,0,2.2-1.1,2.2-2.2v-37.3h26.1c4.5,0,7.5,3.4,7.5,7.1v30.2c0,1.1,1.1,2.2,2.2,2.2h14.2V0h-14.2C51.2,0,50,1.1,50,2.2v33.6h-26.9c-3.7,0-6.3-3-6.3-6.3V2.2c-.4-.7-1.1-2.2-2.6-2.2H0v88.9ZM210.2,20.2c-7.5,0-14.6,3-17.9,5.2-4.9-3.7-11.6-5.2-19.8-5.2-12.3,0-22.8,3.7-24.3,4.5v64.6h13.4c1.5,0,2.2-1.1,2.2-2.6v-51.9c3.4-.7,6-1.1,8.2-1.1,9.3,0,11.6,3.7,11.6,13.1v40c0,1.1.7,2.6,2.2,2.6h11.2c1.5,0,2.2-1.1,2.2-2.6v-50c2.6-1.9,6-3,9.3-3,7.1,0,10.1,3.4,10.1,12v41.1c0,1.1.7,2.6,2.2,2.6h13.4v-45.9c.4-17.2-10.5-23.2-24.3-23.2h0Z"
                />
              </g>
            </g>
          </svg>
        </q-toolbar-title>

        <div class="text-weight-bold" @click="toggleRightDrawer()">
          CV/Resume
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      bordered
      class="bg-primary"
      show-if-above
      side="left"
    >
      <cover-items class="q-mt-xs" />
    </q-drawer>

    <q-drawer
      v-model="rightDrawerOpen"
      class="bg-primary"
      side="right"
      bordered
    >
      <cv-section class="q-mt-xs" />
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import CoverItems from "src/components/coverItem/CoverItems.vue";
import CvSection from "src/components/cv/CvSection.vue";

defineOptions({ name: "MainLayout" });

const leftDrawerOpen = ref(false);
const rightDrawerOpen = ref(false);

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value;
}

function toggleRightDrawer() {
  rightDrawerOpen.value = !rightDrawerOpen.value;
}
</script>
