<template>
  <q-expansion-item
    class="q-mb-md text-weight-bold"
    default-opened
    :icon="processItemIcon(item)"
    :label="item.title"
    expand-icon-class="text-white"
  >
    <q-list dense>
      <q-item
        v-for="project in item.projects"
        clickable
        :key="project._key"
        @click="onClick(project)"
      >
        <q-item-section>
          <q-item-label caption class="q-ml-xl q-pl-sm q-pr-md text-dark">
            {{ project.title }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-expansion-item>
</template>

<script setup>
import {
  sharpStyle,
  sharpCoPresent,
  sharpPhotoAlbum,
  sharpSchool,
  sharpStream,
  sharpTouchApp,
  sharpVideoLibrary,
  sharpWeb,
} from "@quasar/extras/material-icons-sharp";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "CoverItem" });

const appStore = useAppStore();

const props = defineProps({
  item: { type: Object, default: () => {} },
});

const onClick = (project) => {
  appStore.setDialog(project);
};

const processItemIcon = (item) => {
  switch (item.svg) {
    case "sharpCoPresent":
      return sharpCoPresent;
    case "sharpPhotoAlbum":
      return sharpPhotoAlbum;
    case "sharpSchool":
      return sharpSchool;
    case "sharpStream":
      return sharpStream;
    case "sharpStyle":
      return sharpStyle;
    case "sharpVideoLibrary":
      return sharpVideoLibrary;
    case "sharpWeb":
      return sharpWeb;
    default:
      return sharpTouchApp;
  }
};
</script>
